<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'

import { Button } from '@epostbox/ui/button'
import { FormField } from '@epostbox/ui/form'
import { Input } from '@epostbox/ui/input'
import { Select } from '@epostbox/ui/select'

import { useExchangeLogin } from '@composables/email-integration/use-exchange-login'

const emit = defineEmits(['reset-email', 'close-modal'])

const { t } = useI18n()

const schema = z.object({
  lastFetchDate: z.string(),
  displayName: z.string().min(1, 'Display name is required'),
})

const { fetchLoginUrl } = useExchangeLogin()

const openRetrieveSince = ref(false)
const today = new Date()
const todayFormatted = new Date().toISOString()
const lastWeek = new Date(today.setDate(today.getDate() - 7)).toISOString()
const lastMonth = new Date(today.setDate(today.getDate() - 30)).toISOString()

const retrieveSinceOptions = [
  {
    value: todayFormatted,
    label: t('auth.field.last_fetch_date.today'),
  },
  {
    value: lastWeek,
    label: t('auth.field.last_fetch_date.last_week'),
  },
  {
    value: lastMonth,
    label: t('auth.field.last_fetch_date.last_month'),
  },
]

const form = useForm({
  validationSchema: toTypedSchema(schema),
  initialValues: {
    lastFetchDate: todayFormatted,
    displayName: '',
  },
})

const handleSubmit = form.handleSubmit(async values => {
  const url = await fetchLoginUrl({
    lastFetchDate: values.lastFetchDate,
    displayName: values.displayName,
  })
  window.location.href = url
  emit('reset-email')
})
</script>

<template>
  <form class="mt-4" @submit.prevent="handleSubmit">
    <FormField v-slot="{ componentField }" name="displayName" data-e2e="add-exchange-account-display-name-field">
      <Input
        color="beige"
        v-bind="componentField"
        :label="t('auth.field.displayName.label')"
        data-e2e="add-exchange-account-display-name-input"
      />
    </FormField>

    <FormField v-slot="{ componentField }" name="lastFetchDate" data-e2e="add-exchange-account-last-fetch-date-field">
      <Select
        v-bind="componentField"
        v-model:open="openRetrieveSince"
        color="beige"
        :label="t('auth.field.last_fetch_date.label')"
        :items="retrieveSinceOptions"
        data-e2e="add-exchange-account-last-fetch-date-select"
      />
    </FormField>
    <Button
      variant="link"
      class="!font-medium text-brand-550 hover:underline"
      data-e2e="add-exchange-account-reset-provider-button"
      @click="emit('reset-email')"
    >
      <INolasArrowUpRight class="h-6 w-6" />
      {{ t('auth.common.or_choose_another_provider') }}
    </Button>

    <div class="mb-6 mt-8 flex justify-between gap-4">
      <Button
        variant="outline"
        class="w-full"
        type="reset"
        data-e2e="add-exchange-account-cancel-button"
        @click="emit('close-modal')"
      >
        {{ t('common.cancel') }}
      </Button>

      <Button
        type="submit"
        class="w-full"
        :disabled="!form.meta.value.valid"
        data-e2e="add-exchange-account-save-button"
      >
        {{ t('auth.common.add_account') }}
      </Button>
    </div>
  </form>
</template>
