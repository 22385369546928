import { ref, type Component } from 'vue'

const open = ref<boolean>(false)
const openEmail = ref(false)

export interface Section {
  title: string
  name: string
  value: string
  description?: string
  tooltip?: string
  tooltipCategory?: string
  component: Component
}

export interface Tab {
  value: string
  label: string
  children?: Omit<Tab, 'children'>[]
}

export const useSideDrawer = () => {

  const openSideDrawer = () => {
    open.value = true
  }
  const closeSideDrawer = () => {
    open.value = false
  }

  const openEmailSideDrawer = () => {
    openEmail.value = true
  }
  const closeEmailSideDrawer = () => {
    openEmail.value = false
  }

  return {
    open,
    openEmail,
    openSideDrawer,
    closeSideDrawer,
    openEmailSideDrawer,
    closeEmailSideDrawer,
  }
}
