<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'

import { Button } from '@epostbox/ui/button'
import { FormField } from '@epostbox/ui/form'
import { Input } from '@epostbox/ui/input'
import { NumericInput } from '@epostbox/ui/numeric-input'
import { Paragraph } from '@epostbox/ui/paragraph'
import { Select } from '@epostbox/ui/select'

import { useImapAdd } from '@composables/email-integration/use-imap-add'
import { useImapHost } from '@composables/email-integration/use-imap-host'

const emit = defineEmits(['reset-email', 'close-modal', 'close-modal-and-drawer'])

const { t } = useI18n()
type FormValues = z.infer<typeof schema>

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(1, 'Password is required'),
  host: z.string().min(1, 'Host is required'),
  port: z.number().positive().int().nullable(),
  smtpHost: z.string().min(1, 'Host is required'),
  smtpPort: z.number().positive().int().nullable(),
  lastFetchDate: z.string(),
  displayName: z.string().min(1, 'Display name is required'),
})

const { addImapAccount, isPending } = useImapAdd()
const { fetchHost } = useImapHost()

const openRetrieveSince = ref(false)
const formStep = ref<'email' | 'details'>('email')
const today = new Date()
const todayFormatted = new Date().toISOString()
const lastWeek = new Date(today.setDate(today.getDate() - 7)).toISOString()
const lastMonth = new Date(today.setDate(today.getDate() - 30)).toISOString()

const retrieveSinceOptions = [
  {
    value: todayFormatted,
    label: t('auth.field.last_fetch_date.today'),
  },
  {
    value: lastWeek,
    label: t('auth.field.last_fetch_date.last_week'),
  },
  {
    value: lastMonth,
    label: t('auth.field.last_fetch_date.last_month'),
  },
]

const form = useForm<FormValues>({
  validationSchema: toTypedSchema(schema),
  initialValues: {
    email: '',
    password: '',
    host: '',
    port: undefined,
    smtpHost: '',
    smtpPort: undefined,
    lastFetchDate: todayFormatted,
    displayName: '',
  },
})

const canProceed = computed(() => {
  if (formStep.value === 'email') {
    return !!form.values.email
  }
  return form.meta.value.valid
})

const handleContinue = async () => {
  if (!canProceed.value) return

  fetchHost(form.values.email, {
    onSuccess: data => {
      if (!data) return

      form.setFieldValue('host', data.imap_host)
      form.setFieldValue('port', data.imap_port)
      form.setFieldValue('smtpHost', data.smtp_host)
      form.setFieldValue('smtpPort', data.smtp_port)
    },
    onSettled: () => {
      formStep.value = 'details'
    },
  })
}

const handleSubmit = form.handleSubmit(async values => {
  if (!canProceed.value) return

  await addImapAccount(
    {
      email: values.email,
      password: values.password,
      host: values.host,
      port: values.port,
      smtpHost: values.smtpHost,
      smtpPort: values.smtpPort,
      displayName: values.displayName,
      lastFetchDate: values.lastFetchDate,
    },
    {
      onSuccess: () => {
        emit('close-modal')
      },
    }
  )
})
</script>

<template>
  <form class="mt-4" @submit.prevent="handleSubmit">
    <FormField v-slot="{ componentField }" name="email" data-e2e="add-imap-account-email-field">
      <Input
        color="beige"
        v-bind="componentField"
        :disabled="formStep === 'details'"
        :label="t('auth.field.email.label')"
        type="email"
        data-e2e="add-imap-account-email-input"
      />
    </FormField>

    <template v-if="formStep === 'details'">
      <FormField v-slot="{ componentField }" name="password" data-e2e="add-imap-account-password-field">
        <Input
          color="beige"
          v-bind="componentField"
          :label="t('auth.field.password.label')"
          type="password"
          data-e2e="add-imap-account-password-input"
        />
      </FormField>

      <div class="flex justify-between gap-4">
        <FormField v-slot="{ componentField }" name="host" class="w-2/3" data-e2e="add-imap-account-imap-host-field">
          <Input
            color="beige"
            v-bind="componentField"
            :label="t('auth.field.imaphost.label')"
            data-e2e="add-imap-account-imap-host-input"
          />
        </FormField>

        <FormField v-slot="{ componentField }" name="port" class="w-1/3" data-e2e="add-imap-account-imap-port-field">
          <NumericInput
            color="beige"
            v-bind="componentField"
            :label="t('auth.field.imapPort.label')"
            data-e2e="add-imap-account-imap-port-input"
          />
        </FormField>
      </div>

      <div class="flex justify-between gap-4">
        <FormField
          v-slot="{ componentField }"
          name="smtpHost"
          class="w-2/3"
          data-e2e="add-imap-account-smtp-host-field"
        >
          <Input
            color="beige"
            v-bind="componentField"
            :label="t('auth.field.smtpHost.label')"
            data-e2e="add-imap-account-smtp-host-input"
          />
        </FormField>

        <FormField
          v-slot="{ componentField }"
          name="smtpPort"
          class="w-1/3"
          data-e2e="add-imap-account-smtp-port-field"
        >
          <NumericInput
            color="beige"
            v-bind="componentField"
            :label="t('auth.field.smtpPort.label')"
            data-e2e="add-imap-account-smtp-port-input"
          />
        </FormField>
      </div>

      <FormField v-slot="{ componentField }" name="displayName" data-e2e="add-imap-account-display-name-field">
        <Input
          color="beige"
          v-bind="componentField"
          :label="t('auth.field.displayName.label')"
          data-e2e="add-imap-account-display-name-input"
        />
      </FormField>

      <FormField v-slot="{ componentField }" name="lastFetchDate" data-e2e="add-imap-account-last-fetch-date-field">
        <Select
          v-bind="componentField"
          v-model:open="openRetrieveSince"
          color="beige"
          :label="t('auth.field.last_fetch_date.label')"
          :items="retrieveSinceOptions"
          data-e2e="add-imap-account-last-fetch-date-select"
        />
      </FormField>
    </template>
    <Button
      v-if="formStep === 'details'"
      variant="link"
      class="!font-medium text-brand-550 hover:underline"
      data-e2e="add-imap-account-reset-provider-button"
      @click="emit('reset-email')"
    >
      <INolasArrowUpRight class="h-6 w-6" />
      {{ t('auth.common.or_choose_another_provider') }}
    </Button>
    <Paragraph v-if="formStep === 'email'" class="mt-4">
      {{ t('auth.common.by_continuing') }}
      <router-link
        class="text-brand-550 hover:underline"
        to="/terms"
        data-e2e="add-imap-account-terms-link"
        @click="emit('close-modal-and-drawer')"
      >
        {{ t('auth.common.terms_and_services') }}
      </router-link>
      {{ t('auth.common.and') }}
      <router-link
        class="text-brand-550 hover:underline"
        to="/privacy"
        data-e2e="add-imap-account-privacy-link"
        @click="emit('close-modal-and-drawer')"
      >
        {{ t('auth.common.privacy_policy') }}
      </router-link>
      {{ t('auth.common.agree_to_retrieve') }}
    </Paragraph>

    <div class="mb-6 mt-8 flex justify-between gap-4">
      <Button
        variant="outline"
        type="reset"
        class="w-full"
        data-e2e="add-imap-account-cancel-button"
        @click="emit('close-modal')"
      >
        {{ t('common.cancel') }}
      </Button>
      <Button
        v-if="formStep === 'email'"
        type="button"
        class="w-full"
        :disabled="!canProceed"
        data-e2e="add-imap-account-continue-button"
        @click="handleContinue"
      >
        {{ t('common.continue') }}
      </Button>
      <Button
        v-else
        type="submit"
        :disabled="!canProceed || isPending"
        data-e2e="add-imap-account-save-button"
        class="w-full"
      >
        {{ t('auth.common.add_account') }}
      </Button>
    </div>
  </form>
</template>
