<script setup lang="ts">
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { Button } from '@epostbox/ui/button'
import { Dialog, DialogClose } from '@epostbox/ui/dialog'

import ExchangeIntegrationForm from './exchange-integration-form.vue'
import ImapIntegrationForm from './imap-integration-form.vue'

const emit = defineEmits(['close-dialog', 'close-drawer'])
const modelValue = defineModel<boolean>()

const { t } = useI18n()
const accountType = ref<'imap' | 'exchange' | null>(null)
const showIntegrationForm = ref(false)

const backToProvider = () => {
  accountType.value = null
  showIntegrationForm.value = false
}

const onResetEmail = () => {
  backToProvider()
  emit('close-dialog')
}

const onCloseAll = () => {
  backToProvider()
  emit('close-dialog')
  emit('close-drawer')
}

const onContinue = () => {
  if (accountType.value) {
    showIntegrationForm.value = true
  }
}

watch(modelValue, () => {
  showIntegrationForm.value = false
  accountType.value = null
})
</script>

<template>
  <Dialog
    id="email-integration-flow"
    v-model:open="modelValue"
    :title="$t('auth.common.add_email_account')"
    content-class="w-[455px] px-10"
    title-wrapper-classes="pl-2"
    hide-close-button
  >
    <template #trigger>
      <slot />
    </template>
    <template #header-icon>
      <INolasAtSign class="h-10 w-10 min-w-10" />
    </template>
    <div v-if="!showIntegrationForm">
      <div class="mb-6 mt-3 flex justify-between gap-4">
        <Button
          variant="gray"
          :class="[
            'flex h-24 w-full flex-col items-center justify-center gap-2 border-2',
            accountType === 'imap' ? 'border-brand-650' : 'border-transparent',
          ]"
          data-e2e="email-integration-flow-imap-button"
          @click="accountType = 'imap'"
        >
          <INolasEmail class="h-12 w-12" /> {{ t('auth.common.imap') }}
        </Button>
        <Button
          variant="gray"
          :class="[
            'flex h-24 w-full flex-col items-center justify-center gap-2 border-2',
            accountType === 'exchange' ? 'border-brand-650' : 'border-transparent',
          ]"
          data-e2e="email-integration-flow-exchange-button"
          @click="accountType = 'exchange'"
        >
          <INolasExchange class="h-12 w-12" /> {{ t('auth.common.exchange') }}
        </Button>
      </div>

      <div class="mb-6 mt-8 flex justify-between gap-4">
        <DialogClose>
          <Button variant="outline" class="w-1/2" data-e2e="email-integration-flow-cancel-button" @click="onResetEmail">
            {{ t('common.cancel') }}
          </Button>
        </DialogClose>
        <Button class="w-1/2" :disabled="!accountType" data-e2e="email-integration-flow-continue-button" @click="onContinue">
          {{ t('common.continue') }}
        </Button>
      </div>
    </div>

    <ImapIntegrationForm
      v-else-if="accountType === 'imap'"
      data-e2e="imap-integration-form"
      @reset-email="backToProvider"
      @close-modal="onResetEmail"
      @close-modal-and-drawer="onCloseAll"
    />
    <ExchangeIntegrationForm
      v-else-if="accountType === 'exchange'"
      data-e2e="exchange-integration-form"
      @reset-email="backToProvider"
      @close-modal="onResetEmail"
    />
  </Dialog>
</template>
