<script setup lang="ts" generic="T extends string">
import { Button } from '@epostbox/ui/button'
import { Dialog, DialogClose } from '@epostbox/ui/dialog'
import { Paragraph } from '@epostbox/ui/paragraph'

const modelValue = defineModel<boolean>()
defineProps<{
  id?: T
  heading?: string
  isPending?: boolean
  description?: string
  actionLabel?: string
  contentClasses?: string
  hideOverlay?: boolean
  overlayClasses?: string
  icon?: string
  iconClasses?: string
}>()

const emit = defineEmits(['delete'])
</script>

<template>
  <Dialog id="delete-dialog" v-model:open="modelValue" :title="heading || $t('common.are_you_sure')" hide-close-button>
    <template #trigger>
      <slot />
    </template>
    <template #header-icon>
      <INolasTrashOutline class="h-10 w-10 min-w-10" />
    </template>
    <div class="mt-2 w-[498px] px-8">
      <Paragraph v-if="description" size="sm" weight="medium" class="text-neutral-850">
        {{ description }}
      </Paragraph>
      <slot name="elements" />
    </div>
    <template #footer>
      <div class="mt-6 flex w-full gap-3 px-10 pb-10">
        <DialogClose>
          <Button size="lg" class="dialog-action w-full" variant="outline" data-e2e="delete-cancel">
            {{ $t('common.cancel') }}
          </Button>
        </DialogClose>
        <DialogClose>
          <Button
            size="lg"
            class="dialog-action w-full"
            :loading="isPending"
            data-e2e="delete-confirm"
            @click="emit('delete', { ids: [id] })"
          >
            {{ actionLabel || $t('common.delete') }}
          </Button>
        </DialogClose>
      </div>
    </template>
  </Dialog>
</template>
