<script setup lang="ts">
import { Input } from '../input'
import { CustomInputProps } from '../input'

const props = withDefaults(defineProps<CustomInputProps & { isPositive?: boolean }>(), {
  isPositive: false,
})

const pressHandler = (event: KeyboardEvent) => {
  const keyCode = event.key
  const isPositiveMinParam = props.min === undefined ? false : props.min >= 0
  if (keyCode === '.' || keyCode === 'e' || ((isPositiveMinParam || props.isPositive) && keyCode === '-')) {
    event.preventDefault()
    return false
  }
  return true
}
</script>
<template>
  <Input
    v-bind="{ ...props, ...$attrs }"
    type="number"
    :min="props.min === undefined && isPositive ? 0 : props.min"
    inputmode="numeric"
    :label="label"
    @keydown="pressHandler"
  />
</template>
