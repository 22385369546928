<script setup lang="ts">
import { DialogOverlay } from 'radix-vue'
import { computed } from 'vue';

const props = defineProps<{
  class?: string
}>()

const classes = computed(() => [
  'dialog-overlay',
  'fixed',
  'inset-0',
  'z-50',
  'bg-gray-overlay',
  'data-[state=open]:animate-in',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:fade-out-0',
  'data-[state=open]:fade-in-0',
  props.class,
])
</script>

<template>
  <DialogOverlay :class="classes" />
</template>
