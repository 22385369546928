import { DeleteEmailAccount } from '@services/auth-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@epostbox/shared/errors'
import { toast } from '@epostbox/ui/sonner'

import { emailAccountsQuery } from '@composables/email-integration/use-email-accounts'
import { useUser } from '@composables/use-user'

export function useEmailDelete() {
  const { user } = useUser()
  const { t } = useI18n()

  const queryClient = useQueryClient()

  const {
    mutateAsync: deleteEmailAccount,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async (emailAccountId: string) => {
      const { response, data, error } = await DeleteEmailAccount({
        params: {
          path: {
            workspaceId: user.value!.workspaceId,
            emailAccountId,
          },
        },
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }
      return data
    },
    onSuccess: () => {
      toast.success(t('auth.common.email_deleted'), { dataE2e: 'email-delete-success-notification' })
      queryClient.invalidateQueries({ queryKey: emailAccountsQuery._def })
    },
    onError: () => {
      toast.error(t('auth.common.email_not_deleted'), { dataE2e: 'email-delete-error-notification' })
    },
  })

  return { deleteEmailAccount, error: error as Ref<ServiceError | null>, ...mutation }
}

