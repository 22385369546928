<script setup lang="ts">
import { SelectLabel, type SelectLabelProps } from 'radix-vue'

import { cn } from '../../utils'

const props = defineProps<SelectLabelProps & { class?: string }>()
</script>

<template>
  <SelectLabel :class="cn('px-4 py-0.5 text-xs font-semibold text-neutral-850', props.class)">
    <slot />
  </SelectLabel>
</template>
