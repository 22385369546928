<script setup lang="ts">
import { cva } from 'class-variance-authority'
import {
  DialogOverlay,
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue'
import { type HTMLAttributes, computed, ref } from 'vue'

import { cn } from '../../utils'

type ResizablePosition = 't' | 'r' | 'b' | 'l' | 'rt' | 'rb' | 'lt' | 'lb'
interface SheetContentProps extends DialogContentProps {
  class?: HTMLAttributes['class']
  side?: 'left' | 'right' | 'top' | 'bottom'
  resizerWidth?: number
  resizerHeight?: number
  resizerMinWidth?: number
  resizerMaxWidth?: number
  resizable?: Array<ResizablePosition>
  hideCloseButton?: boolean
}

defineOptions({
  inheritAttrs: false,
})

const sheetVariants = cva(
  'fixed z-50 gap-4 bg-background transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-300',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-md-extra',
        right:
          'inset-y-0 right-0 h-full w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-md-extra',
      },
    },
    defaultVariants: {
      side: 'right',
    },
  }
)

const props = defineProps<SheetContentProps>()

const emits = defineEmits<
  DialogContentEmits & {
    blur: []
    focus: []
    change: []
  }
>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

const width = ref(props.resizerWidth || 0)
const height = ref(props.resizerHeight || 0)
const minWidth = ref(props.resizerMinWidth || 282)
const maxW = ref(props.resizerMaxWidth || 480)
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      v-bind="{ ...forwarded, ...$attrs }"
      class="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
      :style="{
        position: 'fixed',
        height: '100%',
        left: props.side !== 'right' ? 0 : 'unset',
        right: props.side === 'right' ? 0 : 'unset',
        top: 0,
        bottom: 0,
        width: `${width}px`,
        '--resizer-width': `${width}px`,
      }"
    />

    <DialogContent
      :class="cn(sheetVariants({ side }), props.class)"
      v-bind="{ ...forwarded, ...$attrs }"
      :focus-trap="false"
      @interact-outside="e => e.preventDefault()"
    >
      <div
        :class="[
          'max-w-md-extra overflow-hidden',
          'transition-all duration-300 ease-in-out',
          cn(sheetVariants({ side: props.side }), props.class),
        ]"
        :min-width="minWidth"
        :width="width"
        :max-width="maxW"
        :height="height"
        :style="{
          position: 'fixed',
          height: '100%',
          left: props.side !== 'right' ? 0 : 'unset',
          top: 0,
          bottom: 0,
          width: `${width}px`,
          '--resizer-width': `${width}px`,
        }"
      >
        <slot />

        <DialogClose
          v-if="!hideCloseButton"
          class="absolute right-3 top-3.5 rounded-md p-2 transition-colors hover:bg-white"
        >
          <INolasXClose class="h-5 w-5 text-neutral-400 hover:text-neutral-500" />
        </DialogClose>
      </div>
    </DialogContent>
  </DialogPortal>
</template>
