import { cva, type VariantProps } from 'class-variance-authority'

export { default as Paragraph } from './paragraph.vue'

export const paragraphVariants = cva('text-gray-600', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-sm',
      base: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    },
    weight: {
      normal: '!font-normal',
      medium: '!font-medium',
      semibold: '!font-semibold',
      bold: '!font-bold',
    },
    color: {
      default: 'text-gray-600',
      'neutral-850': 'text-neutral-850',
      'gray-500': 'text-gray-500',
      'gray-750': 'text-gray-750',
      'gray-1000': 'text-gray-1000',
      'gray-625': 'text-gray-625',
      'gray-warm-600': 'text-gray-warm-600',
    },
  },
  defaultVariants: {
    size: 'md',
    align: 'left',
    weight: 'normal',
    color: 'default',
  },
})

type Variants = VariantProps<typeof paragraphVariants>
export interface ParagraphProps {
  size?: Variants['size']
  align?: Variants['align']
  weight?: Variants['weight']
  color?: Variants['color']
  as?: 'p' | 'span'
  dataE2e?: string
}
