import { cva, type VariantProps } from 'class-variance-authority'
import { type Component } from 'vue'

export { default as Button } from './button.vue'

export const buttonVariants = cva(
  'flex gap-x-1.5 items-center justify-center leading-5 transition-all disabled:pointer-events-none cursor-pointer',
  {
    variants: {
      variant: {
        primary: 'text-white bg-brand-550 hover:bg-brand-650 disabled:bg-neutral-350 disabled:text-neutral-450',
        secondary:
          'text-gray-1000 bg-secondary hover:bg-secondary-25 disabled:bg-neutral-375 disabled:text-neutral-475',
        outline:
          'text-brand-550 border border-gray-475 bg-transparent hover:bg-gray-warm-50 hover:text-brand-650 disabled:border-neutral-550 disabled:text-neutral-650',
        beige:
          'text-gray-1000 bg-gray-275 hover:bg-gray-550 active:text-white active:bg-brand-550 disabled:bg-neutral-350 disabled:text-neutral-450',
        sand: 'text-gray-1000 bg-neutal-150 hover:bg-neutral-250 active:text-white active:bg-brand-550 disabled:bg-neutral-375 disabled:text-neutral-475',
        gray: 'text-gray-1000 bg-gray-warm-50 [&>svg]:text-gray-150 hover:bg-gray-warm-75 active:bg-gray-275 disabled:bg-neutral-375 disabled:text-neutral-475',
        menu: 'text-gray-1000 !font-medium bg-transparent hover:bg-gray-warm-75 active:bg-gray-275',
        social:
          'text-gray-700 border border-gray-350 bg-white hover:bg-gray-warm-50 hover:text-gray-800 disabled:border-neutral-550 disabled:text-neutral-650',
        link: '!p-0 text-gray-750 hover:text-brand-650 disabled:text-neutral-450',
        'link-blue': '!p-0 text-brand-550 hover:text-brand-650 disabled:text-neutral-475',
        tertiary:
          'bg-gray-550 hover:bg-gray-575 active:bg-brand-550 data-[state=open]:bg-brand-550 [&[data-state=open]>svg]:text-white disabled:bg-neutral-350 disabled:text-neutral-450',
        transparent: 'bg-transparent',
      },
      size: {
        xs: 'h-6 p-0 text-xs',
        sm: 'h-7 py-1 px-2 text-xs',
        md: 'h-9 py-1 px-3 text-sm',
        lg: 'h-10 py-3 px-2 text-sm',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
)

type Variants = VariantProps<typeof buttonVariants>
export interface ButtonProps {
  size?: Variants['size']
  variant?: Variants['variant']
  as?: 'button' | 'a' | 'router-link'
  rounded?: boolean
  disabled?: boolean
  loading?: boolean
  icon?: Component
}
