import { GetImapHosts } from '@services/auth-api'
import { useMutation } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@epostbox/shared/errors'
import { toast } from '@epostbox/ui/sonner'

interface ImapHostInfo {
  imap_host: string
  imap_port: number | null
  smtp_host: string
  smtp_port: number | null
}

async function getImapHost(email: string): Promise<ImapHostInfo> {
  const { response, data, error } = await GetImapHosts({
    params: {
      query: {
        email,
      },
    },
  })

  if (!response.ok || !data) {
    throw ServiceError.fromResponse(error)
  }
  return data
}

export function useImapHost() {
  const { t } = useI18n()

  const { mutate: fetchHost, error } = useMutation({
    mutationFn: getImapHost,
    onSuccess: () => {
      toast.success(t('auth.common.imap_host_fetched'))
    },
    onError: () => {
      toast.error(t('auth.common.imap_host_not_fetched'))
    },
  })

  return {
    fetchHost,
    error: error as Ref<ServiceError | null>,
  }
}
