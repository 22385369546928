import createClient from 'openapi-fetch'
import type { FetchOptions, ClientOptions, FetchResponse } from 'openapi-fetch'
import type { PathsWithMethod, FilterKeys, HttpMethod } from 'openapi-typescript-helpers'

import type { paths } from './types'

export const WorkbenchAPIClient = (options: ClientOptions) => {
  const client = createClient<paths>(options)

  function define<Method extends HttpMethod>(method: Method) {
    return <Path extends PathsWithMethod<paths, Method>>(path: Path) =>
      <Init extends FetchOptions<FilterKeys<paths[Path], Method>>>(
        options: Init
      ): Promise<FetchResponse<paths[Path][Method], Init, `${string}/${string}`>> => {
        // @ts-expect-error stupid types
        return client[method.toUpperCase() as UppercaseMethods](path, options)
      }
  }

  return {
    get: define('get'),
    post: define('post'),
    put: define('put'),
    patch: define('patch'),
    delete: define('delete'),
    getUrl: <P extends keyof paths>(path: P) => new URL(`${options.baseUrl}/${path}`),
    use: client.use,
  }
}

type Endpoint<Init extends FetchOptions<FilterKeys<keyof paths, HttpMethod>>> = (
  options: Init
) => Promise<FetchResponse<paths[keyof paths][HttpMethod], Init, `${string}/${string}`>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InferResponse<E extends Endpoint<any>> = Exclude<Awaited<ReturnType<E>>['data'], undefined>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InferBody<E extends Endpoint<any>> = Exclude<Parameters<E>['0']['body'], undefined>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InferRequest<E extends Endpoint<any>> = Exclude<Parameters<E>['0'], undefined>

export type { paths, components } from './types'
