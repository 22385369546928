import { AuthAPIClient, InferBody, InferRequest, InferResponse } from '@epostbox/auth-api/client'

import { accessToken } from './retrieve-access-token'

const baseUrl = import.meta.env.DEV ? `${location.origin}/api/auth` : import.meta.env.VITE_AUTH_API_URL

const Auth = AuthAPIClient({ baseUrl, credentials: 'include' })

Auth.use({
  async onRequest({ request }) {
    if (accessToken) {
      request.headers.set('authorization', `Bearer ${accessToken}`)
    }
    return request
  },
})

export const AuthorizeURL = Auth.getUrl('/api/authorize')
export const LogoutURL = Auth.getUrl('/api/flow/logout')

export const TokenURL = Auth.getUrl('/api/token')
export const GetToken = Auth.post('/api/token')
// @ts-expect-error TODO: update types
export type GetToken = InferResponse<typeof GetToken>
// @ts-expect-error TODO: update types
export type GetTokenRequest = InferBody<typeof GetToken>

export const GetCurrentUser = Auth.get('/api/session/me')
export type CurrentUser = InferResponse<typeof GetCurrentUser>

export const AcceptInvitation = Auth.put('/api/workspaces/{workspaceId}/members/{userId}')
export type AcceptInvitation = InferResponse<typeof AcceptInvitation>
export type AcceptInvitationRequest = InferRequest<typeof AcceptInvitation>['params']['path']

export const SetupAccountEmail = Auth.patch('/api/user/{workspaceId}/setup-account-email')
export type SetupAccountEmail = InferResponse<typeof SetupAccountEmail>
export type SetupAccountEmailRequest = Exclude<InferRequest<typeof SetupAccountEmail>['body'], undefined>
export type SetupAccountEmailRequestParam = InferRequest<typeof SetupAccountEmail>['params']['path']

export const UpdatePreferences = Auth.put('/api/me/preferences')
export type UpdatePreferencesRequest = Exclude<InferRequest<typeof UpdatePreferences>['body'], undefined>

export const CreateDomain = Auth.post('/api/domains/{workspaceId}') //.auth.api.domains[':workspaceId'].$post
export type CreateDomainRequest = Exclude<InferRequest<typeof CreateDomain>['body'], undefined>

export const ValidateDomain = Auth.put('/api/domains') //.auth.api.domains.$put
export type ValidateDomainRequest = Exclude<InferRequest<typeof ValidateDomain>['body'], undefined>

export const DeleteDomain = Auth.delete('/api/domains') //.auth.api.domains[':id'].$delete
export type DeleteDomainRequest = InferRequest<typeof DeleteDomain>['params']['query']

export const GetSAMLConfig = Auth.get('/api/sp/{workspaceId}') //.auth.api.sp[':workspaceId'].$get
export type GetSAMLConfig = InferResponse<typeof GetSAMLConfig>

export const SetSAMLConfig = Auth.post('/api/sp/{workspaceId}') //.auth.api.sp[':workspaceId'].$post
export type SetSAMLConfig = InferResponse<typeof SetSAMLConfig>
export type SetSAMLConfigRequest = Exclude<InferRequest<typeof SetSAMLConfig>['body'], undefined>

export const ToggleSAML = Auth.put('/api/sp/{workspaceId}/status') //.auth.api.sp[':workspaceId'].status.$put
export type ToggleSAML = InferResponse<typeof ToggleSAML>
export type ToggleSAMLRequest = Exclude<InferRequest<typeof ToggleSAML>['body'], undefined>

export const GetSCIMConfig = Auth.get('/api/scim/{workspaceId}') //.auth.api.scim[':workspaceId'].$get
export type GetSCIMConfig = InferResponse<typeof GetSCIMConfig>

export const GenerateSCIMToken = Auth.put('/api/scim/{workspaceId}') //.auth.api.scim[':workspaceId'].$put

export const GetWorkspace = Auth.get('/api/workspaces/{workspaceId}') //.auth.api.workspaces[':workspaceId'].$get
export type Workspace = InferResponse<typeof GetWorkspace>

export const Register = Auth.post('/api/flow/register') //.auth.api.flow.register.$post
export type Register = InferResponse<typeof Register>
export type RegisterRequest = Exclude<InferRequest<typeof Register>['body'], undefined>

export const Login = Auth.post('/api/flow/login') //.auth.api.flow.login.$post
export type Login = InferResponse<typeof Login>
export type LoginRequest = Exclude<InferRequest<typeof Login>['body'], undefined>

export const LoginSAML = Auth.post('/api/flow/saml') //.auth.api.flow.saml.$post
export type LoginSAML = InferResponse<typeof LoginSAML>
export type LoginSAMLRequest = Exclude<InferRequest<typeof LoginSAML>['body'], undefined>

export const ForgotPassword = Auth.get('/api/flow/password') //.auth.api.flow.password.$get
export type ForgotPassword = InferResponse<typeof ForgotPassword>
export type ForgotPasswordRequest = InferRequest<typeof ForgotPassword>['params']['query']

export const ResetPassword = Auth.put('/api/flow/password') //.auth.api.flow.password.$put
export type ResetPassword = InferResponse<typeof ResetPassword>
export type ResetPasswordRequest = Exclude<InferRequest<typeof ResetPassword>['body'], undefined>

export const GetEmailAccounts = Auth.get('/api/email-accounts')
export type GetEmailAccounts = InferResponse<typeof GetEmailAccounts>['accounts']

export const GetImapHosts = Auth.get('/api/email-accounts/imap-host')
export type GetImapHosts = InferResponse<typeof GetImapHosts>

export const AddImapAccount = Auth.post('/api/email-accounts/imap')
export type AddImapAccount = InferResponse<typeof AddImapAccount>
export type AddImapAccountRequest = Exclude<InferRequest<typeof AddImapAccount>['body'], undefined>

export const ExchangeCallback = Auth.get('/api/email-accounts/exchange/callback')
export type ExchangeCallback = InferResponse<typeof ExchangeCallback>

export const ExchangeLogin = Auth.post('/api/email-accounts/exchange/login')
export type ExchangeLogin = InferResponse<typeof ExchangeLogin>

export const UpdateEmailAccount = Auth.patch('/api/email-accounts/{emailAccountId}')
export type UpdateEmailAccount = InferResponse<typeof ExchangeLogin>

export const FetchEmailInboxes = Auth.post('/api/email-accounts/fetch-initiate')
export type FetchEmailInboxes = InferResponse<typeof FetchEmailInboxes>

export const DeleteEmailAccount = Auth.delete('/api/email-accounts/{emailAccountId}')
export type DeleteEmailAccount = InferResponse<typeof DeleteEmailAccount>
