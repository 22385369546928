import { AcceptInvitation, AcceptInvitationRequest, GetCurrentUser } from '@services/auth-api'
import { QK } from '@services/query-keys'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { type Ref } from 'vue'

import { ServiceError } from '@epostbox/shared/errors'

import { useSession } from './use-session'

export function useUser() {
  const { isLoggedIn } = useSession()
  const { acceptUserInvitation } = useAcceptUserInvitation()

  const {
    data: user,
    error,
    ...query
  } = useQuery({
    enabled: () => isLoggedIn.value,
    queryKey: QK.me._def,
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const refresh_token = localStorage.getItem('refresh_token')

      const { response, data, error } = await GetCurrentUser({
        headers: refresh_token ? { 'x-refreshtoken': refresh_token } : undefined,
      })

      if (response.status !== 200 || !data) {
        throw ServiceError.fromResponse(error)
      }

      if (data.workspaces.length === 1 && data.status !== 'joined') {
        await acceptUserInvitation({ workspaceId: data.workspaces[0].id, userId: data.id })
      }
      return data
    },
  })

  return { user, error: error as Ref<ServiceError | null>, ...query }
}

export function useAcceptUserInvitation() {
  const queryClient = useQueryClient()

  const {
    mutateAsync: acceptUserInvitation,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async (variables: AcceptInvitationRequest) => {
      const { response, data, error } = await AcceptInvitation({
        params: {
          path: { workspaceId: variables.workspaceId, userId: variables.userId },
        },
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }

      return data
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: QK.me._def })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: QK.me._def })
    },
  })

  return { acceptUserInvitation, error: error as Ref<ServiceError | null>, ...mutation }
}
