<script setup lang="ts">
import { ClassValue } from 'clsx'
import { DialogRoot, type DialogContentProps } from 'radix-vue'
import { useSlots } from 'vue'

import { cn } from '../../utils'

import { DialogTrigger, DialogHeader, DialogTitle, DialogDescription, DialogContent, DialogFooter } from '.'

const slots = useSlots()

const props = defineProps<
  DialogContentProps & {
    id: string
    class?: ClassValue
    contentClass?: ClassValue
    hideCloseButton?: boolean
    hideOverlay?: boolean
    overlayClasses?: ClassValue
    footerClasses?: ClassValue
    contentSectionClasses?: ClassValue
    titleClasses?: ClassValue
    dataE2e?: string
    description?: string
    title?: string
    titleWrapperClasses?: ClassValue
  }
>()
</script>

<template>
  <DialogRoot :data-e2e="`${id}-modal`">
    <DialogTrigger as-child>
      <slot name="trigger" />
    </DialogTrigger>
    <DialogContent
      :class="cn('', props.class)"
      :hide-close-button="hideCloseButton"
      :content-class="contentClass"
      :data-e2e="`${id}-modal-content`"
    >
      <template v-if="!slots.preview" #header>
        <div v-if="title" :class="cn('px-10 pt-10', titleWrapperClasses)">
          <DialogHeader class="flex flex-col gap-5" :data-e2e="`${id}-modal-header`">
            <DialogTitle
              :class="cn('flex items-center justify-between gap-3 text-xl font-medium', titleClasses)"
              :data-e2e="`${id}-modal-title`"
            >
              <div class="flex items-center gap-3 text-xl font-medium">
                <slot name="header-icon" />
                <span class="truncate">{{ title }}</span>
              </div>
              <slot name="header-action" />
            </DialogTitle>
            <DialogDescription v-if="description" :data-e2e="`${id}-modal-description`">
              {{ description }}
            </DialogDescription>
          </DialogHeader>
        </div>
      </template>

      <template #content>
        <div class="flex h-full w-full items-center">
          <slot name="preview" />
          <div :class="cn('h-full w-full px-2', contentSectionClasses)">
            <slot />
          </div>
        </div>
      </template>

      <template v-if="!slots.preview" #footer>
        <DialogFooter
          v-if="slots.footer"
          :class="cn('w-full bg-white', footerClasses)"
          :data-e2e="`${id}-modal-footer`"
        >
          <slot name="footer" />
        </DialogFooter>
      </template>
    </DialogContent>
  </DialogRoot>
</template>
