<script setup lang="ts">
import { computed } from 'vue'

import { Avatar, AvatarFallback } from '@epostbox/ui/avatar'
import { Heading } from '@epostbox/ui/heading'
import { IconButton } from '@epostbox/ui/icon-button'
import { Paragraph } from '@epostbox/ui/paragraph'
import { cn } from '@epostbox/ui/utils'

import { useLogout } from '@composables/use-logout'
import { useUser } from '@composables/use-user'

defineProps<{
  version?: {
    build: string
    date: string
  }
}>()

const { user } = useUser()
const { logout } = useLogout()
const fallback = computed(() => {
  return user.value?.email.slice(0, 2).toUpperCase()
})
const name = computed(() => {
  return user.value?.email.split('@')[0]
})

const emit = defineEmits(['open-side-drawer'])
</script>

<template>
  <div class="mt-auto flex flex-col">
    <div class="flex items-center justify-between gap-x-3 border-t border-neutral-75 p-4">
      <div class="flex items-center gap-x-3" data-e2e="email-integration-sidedrawer-trigger" @click="emit('open-side-drawer')">
        <Avatar
          shape="circle"
          size="xs"
          :class="cn('my-auto h-10 w-10 cursor-pointer border border-[#9296E5] bg-[#A3A6FF]')"
        >
          <AvatarFallback :class="cn('text-sm font-medium uppercase leading-4.5 text-white')">
            {{ fallback }}
          </AvatarFallback>
        </Avatar>
        <div class="max-w-40">
          <Heading as="h3" class="max-w-40 truncate text-sm font-medium text-gray-1000">
            {{ name }}
          </Heading>
          <Paragraph v-if="user?.email" size="sm" class="max-w-40 truncate text-gray-500">
            {{ user?.email }}
          </Paragraph>
        </div>
      </div>
      <IconButton size="md" variant="transparent" data-e2e="logout-button" @click="logout">
        <INolasLogOut class="h-6 w-6 text-neutral-850" />
      </IconButton>
    </div>

    <div v-if="version" class="px-4 pb-2">
      <Paragraph size="xs" class="text-gray-500 opacity-60">
        Build version {{ version.build }} created on {{ version.date }}.
      </Paragraph>
    </div>
  </div>
</template>
