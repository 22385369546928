<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue'

import { Button } from '@epostbox/ui/button'
import { Heading } from '@epostbox/ui/heading'
import { IconButton } from '@epostbox/ui/icon-button'
import { Input } from '@epostbox/ui/input'
import { Paragraph } from '@epostbox/ui/paragraph'
import { SideDrawer } from '@epostbox/ui/side-drawer'

import { useEmailAccounts } from '@composables/email-integration/use-email-accounts'
import { useEmailChangeName } from '@composables/email-integration/use-email-change-name'
import { useEmailDelete } from '@composables/email-integration/use-email-delete'
import { useEmailFetchInboxes } from '@composables/email-integration/use-email-fetch-inboxes'
import { useSideDrawer } from '@composables/use-sidedrawer'

import DeleteDialog from '@modules/workspaces/components/common/delete-dialog.vue'

import EmailIntegrationFlow from './email-integration-flow.vue'

const FETCH_THROTTLE = 15_000

withDefaults(
  defineProps<{
    title?: string
  }>(),
  {
    title: '',
  }
)

onBeforeUnmount(() => {
  closeEmailSideDrawer()
})

const { closeEmailSideDrawer } = useSideDrawer()
const { emailAccounts } = useEmailAccounts()
const { deleteEmailAccount } = useEmailDelete()
const { updateEmailAccount } = useEmailChangeName()
const { fetchEmailInboxes, isPending } = useEmailFetchInboxes()

const showAddFlow = ref(false)
const showDeleteEmailAccount = ref(false)
const editingAccountId = ref<string | null>(null)
const deletingAccountId = ref<string | null>(null)
const editingDisplayName = ref('')

const openEmail = defineModel<boolean>('openEmail', { default: false })

const startEditing = (account: { id: string; displayName: string }) => {
  editingAccountId.value = account.id
  editingDisplayName.value = account.displayName
}

const stopEditing = () => {
  editingAccountId.value = null
  editingDisplayName.value = ''
}

const updateDisplayName = (accountId: string) => {
  if (editingDisplayName.value !== emailAccounts.value?.find(a => a.id === accountId)?.displayName)
    updateEmailAccount({ id: accountId, displayName: editingDisplayName.value })
  stopEditing()
}

const isRefreshDisabled = ref(false)

const fetchInboxes = () => {
  if (isRefreshDisabled.value) return

  fetchEmailInboxes()
  isRefreshDisabled.value = true

  setTimeout(() => {
    isRefreshDisabled.value = false
  }, FETCH_THROTTLE)
}

const askForDeleteConfirmation = (accountId: string) => {
  deletingAccountId.value = accountId
  showDeleteEmailAccount.value = true
}
</script>

<template>
  <SideDrawer
    v-model:open="openEmail"
    data-e2e="email-integration-side-drawer"
    :title="title"
    @close="closeEmailSideDrawer"
  >
    <div class="container mx-auto h-full">
      <div v-if="emailAccounts && emailAccounts.length > 0">
        <div class="mb-2 flex">
          <Paragraph class="mt-1">{{ $t('auth.common.email_accounts') }}</Paragraph>
          <IconButton
            size="sm"
            variant="transparent"
            class="ml-auto"
            :disabled="isRefreshDisabled"
            @click="fetchInboxes"
          >
            <INolasRefresh
              :class="[
                'h-6 w-6',
                isPending ? 'animate-spin' : '',
                isRefreshDisabled ? 'text-gray-400' : 'text-gray-525',
              ]"
              data-e2e="email-integration-refresh-button"
            />
          </IconButton>
        </div>
        <ul class="mb-3 rounded-lg bg-white" data-e2e="email-integration-accounts-list">
          <li v-for="account in emailAccounts" :key="account?.id">
            <div class="flex items-center gap-3 p-3" :data-e2e="`email-integration-account-${account.id}`">
              <div class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200">
                <INolasEmail v-if="account.protocol === 'imap'" data-e2e="imap-icon" class="h-8 w-8 text-gray-525" />
                <INolasExchange
                  v-else-if="account.protocol === 'ews'"
                  data-e2e="ews-icon"
                  class="h-8 w-8 text-gray-525"
                />
              </div>

              <template v-if="editingAccountId !== account.id">
                <div>
                  <div
                    class="max-w-64 truncate text-sm font-medium text-gray-1000"
                    data-e2e="email-integration-account-name"
                  >
                    {{ account.displayName }}
                  </div>
                  <div class="text-xs text-gray-625" data-e2e="email-integration-account-email">
                    {{ account.email }}
                  </div>
                </div>
                <IconButton
                  size="sm"
                  variant="transparent"
                  class="ml-auto"
                  data-e2e="email-integration-account-edit-button"
                  @click="startEditing({ id: account.id, displayName: account.displayName as string })"
                >
                  <INolasEdit02 class="h-6 w-6 text-gray-525" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="transparent"
                  data-e2e="email-integration-account-delete-button"
                  @click="askForDeleteConfirmation(account.id)"
                >
                  <INolasTrashOutline class="h-6 w-6 text-gray-525" />
                </IconButton>
              </template>

              <template v-else>
                <Input
                  v-model="editingDisplayName"
                  class="h-8"
                  color="beige"
                  data-e2e="email-integration-account-edit-input"
                  @update:model-value="editingDisplayName = $event as string"
                  @keydown.enter="editingDisplayName && updateDisplayName(account.id)"
                />
                <IconButton
                  size="sm"
                  variant="transparent"
                  :disabled="!editingDisplayName"
                  data-e2e="email-integration-account-save-edit-button"
                  @click="updateDisplayName(account.id)"
                >
                  <INolasCheck :class="['h-6 w-6', editingDisplayName ? 'text-green-500' : 'text-gray-525']" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="transparent"
                  data-e2e="email-integration-account-cancel-edit-button"
                  @click="stopEditing()"
                >
                  <INolasXClose class="h-6 w-6 text-red-500" />
                </IconButton>
              </template>
            </div>
            <hr v-if="emailAccounts?.indexOf(account) !== emailAccounts?.length - 1" class="border-t-2" />
          </li>
        </ul>
        <Button variant="link" class="text-brand-550" data-e2e="add-email-account-button" @click="showAddFlow = true">
          <INolasPlus class="h-5 w-5" /> {{ $t('auth.common.add_email_account') }}
        </Button>
      </div>
      <div v-else class="flex h-full flex-col items-center justify-center gap-6" data-e2e="no-email-accounts-added">
        <div class="flex h-24 w-24 items-center justify-center rounded-full bg-gray-275 text-white">
          <INolasAtSign class="h-12 w-12 text-gray-525" />
        </div>
        <Heading size="md" class="font-medium text-gray-1000">{{ $t('auth.common.no_email_accounts_added') }}</Heading>
        <Paragraph class="mx-10 text-center font-medium text-gray-1000">
          {{ $t('auth.common.no_email_accounts_added_desc') }}
        </Paragraph>
        <Button data-e2e="add-email-account-button" @click="showAddFlow = true">
          <INolasPlus class="h-5 w-5" /> {{ $t('auth.common.add_account') }}
        </Button>
      </div>

      <EmailIntegrationFlow
        v-model="showAddFlow"
        @close-dialog="showAddFlow = false"
        @close-drawer="closeEmailSideDrawer"
      />
    </div>
    <DeleteDialog
      v-model="showDeleteEmailAccount"
      :description="
        $t('auth.common.delete_email_account_desc', {
          email: emailAccounts?.find(a => a.id === deletingAccountId)?.email,
        })
      "
      data-e2e="email-integration-delete-account-dialog"
      @delete="deleteEmailAccount(deletingAccountId as string)"
    />
  </SideDrawer>
</template>
