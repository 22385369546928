<script setup lang="ts">
import { DialogClose, type DialogCloseProps } from 'radix-vue'

import { cn } from '../../utils'

const props = defineProps<DialogCloseProps & { class?: string }>()
</script>

<template>
  <DialogClose v-bind="props" as-child :class="cn('dialog-close', props.class)">
    <slot />
  </DialogClose>
</template>
