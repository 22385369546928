import { UpdateEmailAccount } from '@services/auth-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@epostbox/shared/errors'
import { toast } from '@epostbox/ui/sonner'

import { emailAccountsQuery } from '@composables/email-integration/use-email-accounts'

export function useEmailChangeName() {
  const { t } = useI18n()

  const queryClient = useQueryClient()

  const {
    mutateAsync: updateEmailAccount,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async ({id, displayName}: {id: string, displayName: string}) => {
      const { response, data, error } = await UpdateEmailAccount({
        params: {
          path: {
            emailAccountId: id,
          },
        },
        body: {
          displayName,
        },
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }
      return data
    },
    onSuccess: () => {
      toast.success(t('auth.common.email_name_changed'), { dataE2e: 'email-name-change-success-notification' })
      queryClient.invalidateQueries({ queryKey: emailAccountsQuery._def })
    },
    onError: () => {
      toast.error(t('auth.common.email_name_not_changed'), { dataE2e: 'email-name-change-error-notification' })
    },
  })

  return { updateEmailAccount, error: error as Ref<ServiceError | null>, ...mutation }
}
